export const environment = {
  production: true,
  apiUrl: 'https://gbe5q72m7dy3rd3x2676y6hxpi0ztpnj.lambda-url.us-east-1.on.aws/',
  geoApi: '',
  mapEndpoint: 'https://maps.googleapis.com/maps/api/js',
  mapKey: 'AIzaSyBxbjK-hWHC35NMJGTvvXV5L8BWMVivItg',
  urlImages: 'https://efaysto-dev-20240708151619876300000001.s3.us-east-1.amazonaws.com/',
  efaystoWallet: '0x9745DcAb234f4DcF8A299B22e2E18cE31fe6A04F',
  efaystoContactName: 'SIMON_TEST_TOKEN',
  RWA_CONTRACT: '0x0fc90923fbda4bdcee8ea7e996ead50911e0d7b4'
};