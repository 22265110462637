import { Injectable } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { AlertController, LoadingController } from '@ionic/angular';
import { ModalController, ToastController } from '@ionic/angular/standalone';
import { LoadingService } from './loading.service';
import { filter } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class IonicService {
    LOADING: any;
    alert: any = [];
    private navigationLoadingId: string | null = null;
    private navigationEndTimeout: any = null;

    constructor(
        private router: Router,
        private loadingController: LoadingController,
        private alertController: AlertController,
        private modalController: ModalController,
        private toastController: ToastController,
        private loadingService: LoadingService
    ) {
        // Subscribe to navigation events to automatically manage loading indicator
        this.setupNavigationLoadingListener();
    }

    /**
     * Set up listeners for router navigation events to show/hide loading indicators
     */
    private setupNavigationLoadingListener() {
        this.router.events.pipe(
            filter(event => 
                event instanceof NavigationStart ||
                event instanceof NavigationEnd ||
                event instanceof NavigationCancel ||
                event instanceof NavigationError
            )
        ).subscribe(event => {
            // When navigation begins
            if (event instanceof NavigationStart) {
                // Cancel any pending timeout to ensure a previous loading isn't hidden
                if (this.navigationEndTimeout) {
                    clearTimeout(this.navigationEndTimeout);
                    this.navigationEndTimeout = null;
                }
                
                // Clear any active loading to avoid duplicates
                if (this.navigationLoadingId) {
                    this.loadingService.hideById(this.navigationLoadingId);
                }
                
                // Generate a unique ID for this navigation
                this.navigationLoadingId = `nav-${Date.now()}`;
                
                // Show navigation-specific loading
                this.loadingService.showWithId(this.navigationLoadingId);
            }
            
            // When navigation ends (success, cancel, or error)
            if (event instanceof NavigationEnd || 
                event instanceof NavigationCancel || 
                event instanceof NavigationError) {
                
                if (this.navigationLoadingId) {
                    // Give destination page time to take control of the loading
                    this.navigationEndTimeout = setTimeout(() => {
                        // Only hide if explicit control of loading hasn't been taken
                        if (this.navigationLoadingId) {
                            this.loadingService.hideById(this.navigationLoadingId);
                            this.navigationLoadingId = null;
                        }
                        this.navigationEndTimeout = null;
                    }, 300); // Small delay to allow page to initialize
                }
            }
        });
    }

    /**
     * Notify that the page has taken control of the navigation loading
     * This prevents the loading indicator from being automatically hidden at the end of navigation
     */
    takeControlOfNavigationLoading() {
        if (this.navigationEndTimeout) {
            clearTimeout(this.navigationEndTimeout);
            this.navigationEndTimeout = null;
        }
        
        // Save ID for reference
        const takenControlId = this.navigationLoadingId;
        // Reset ID to prevent automatic hiding
        this.navigationLoadingId = null;
        
        // Return ID so the page can hide it when finished
        return takenControlId;
    }

    /**
     * Enhanced method to navigate to a page and show loading
     * @param page Route to navigate to
     * @param param Optional URL parameter
     * @param replace Replace URL in history
     */
    goPage(page: string, param?: string, replace: boolean = true) {
        try {
            // Ensure there are no lingering loaders from previous navigations
            this.loadingService.getActiveLoadingIds()
                .filter(id => id.startsWith('nav-') && id !== this.navigationLoadingId)
                .forEach(id => this.loadingService.hideById(id));
                
        } catch (e) {
            console.error('Error in goPage:', e);
        }
    
        const urlParam = !!param ? param : '';
        this.router.navigate([`${page}/${urlParam}`], { replaceUrl: replace });
    }

    /**
     * Create and present a modal
     */
    async showModal(
        modalPage: any,
        cssClass = [''],
        componentProps?: any,
        allowDismiss?: boolean
    ) {
        const modal = await this.modalController.create({
            component: modalPage,
            componentProps,
            cssClass,
            backdropDismiss: allowDismiss != null ? allowDismiss : true
        });
        await modal.present();
        return modal;
    }

    /**
     * Create and present an alert
     */
    async showAlert(
        header: string,
        message: string,
        buttons?: any[],
        inputs?: any[],
        id?: string
    ) {
        if (this.alert.length > 0) {
            this.alert.forEach(async (alert: any) => {
                await alert.dismiss();
            });
            this.alert = [];
        }
        const alert = await this.alertController.create({
            mode: 'ios',
            header,
            message,
            id: id ?? 'alert',
        });
        this.alert.push(alert);

        if (Boolean(buttons?.length) && buttons) {
            alert.buttons = buttons;
        }

        if (Boolean(inputs?.length) && inputs) {
            alert.inputs = inputs;
        }

        await alert.present();

        return alert.onDidDismiss();
    }

    /**
     * Dismiss the active modal
     */
    dismissModal(data?: unknown) {
        this.modalController.dismiss(data ?? null);
    }

    /**
     * Dismiss the active alert
     */
    dismissAlert() {
        this.alertController.dismiss();
    }

    /**
     * Show a loading indicator
     */
    async showLoading(message?: string, duration?: number) {
        try {
            this.loadingService.show();
        } catch (e) {
            console.error('Error showing custom loading:', e);
        }

        this.LOADING = await this.loadingController.create({
            message: message || 'Loading',
            spinner: 'bubbles',
        });

        if (Boolean(duration)) {
            this.LOADING.duration = duration;
        }

        await this.LOADING.present();
    }

    /**
     * Dismiss the active loading indicator
     */
    async dismissLoading() {
        try {
            this.loadingService.hide();
        } catch (e) {
            console.error('Error hiding custom loading:', e);
        }

        if (this.LOADING) {
            await this.LOADING.dismiss();
        }
    }
    
    /**
     * Show a toast message with specified type and duration
     */
    async showToast(message: string, type: 'success' | 'info' | 'warning' | 'error', duration = 3000) {
        const toast = await this.toastController.create({
            position: 'top',
            message,
            duration,
            cssClass: ['toast-item', `toast-${type}`],
            icon: type === 'success' ? 'assets/icon/toast-success.svg' : 'assets/icon/toast-error.svg',
            color: 'unset',
        });
        toast.present();
    }
}