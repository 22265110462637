import { Injectable, signal, ApplicationRef } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  // Loading state management
  private loadingSignal = signal<boolean>(false);
  private loadingCount = 0;
  private maxLoadingTime = 8000; // 8 second maximum for any operation
  private timeoutIds = new Map<string, any>(); // Map for multiple timeouts
  
  // Map to track loadings by ID
  private loadingIds = new Map<string, boolean>();
  
  // Debug mode to help in development
  private debugMode = false; // Set to true for more detailed logs

  constructor(private appRef: ApplicationRef) {
    // In development, set up an interval to detect orphaned loadings
    if (this.debugMode) {
      setInterval(() => this.checkOrphanedLoadings(), 5000);
    }
  }

  /**
   * Show global loading indicator
   */
  show() {
    this.loadingCount++;
    this.loadingSignal.set(true);
    
    // Force change detection in the application
    this.detectChanges();
    
    // Set maximum time for loading
    const timeoutId = setTimeout(() => {
      console.warn('Loading timeout triggered - forcing reset');
      this.forceReset();
    }, this.maxLoadingTime);
    
    this.timeoutIds.set('global', timeoutId);
  }

  /**
   * Show loading with specific ID for tracking different loading types
   */
  showWithId(id: string) {
    // If already exists, don't increment counter
    if (this.loadingIds.has(id)) {
      return;
    }
    
    this.loadingIds.set(id, true);
    this.loadingCount++;
    this.loadingSignal.set(true);
    
    this.detectChanges();
    
    // Set maximum time for this specific loading
    const timeoutId = setTimeout(() => {
      console.warn(`Loading timeout triggered for ID: ${id} - hiding`);
      this.hideById(id);
    }, this.maxLoadingTime);
    
    this.timeoutIds.set(id, timeoutId);
  }

  /**
   * Hide global loading indicator
   */
  hide() {
    if (this.loadingCount <= 0) {
      console.warn('LoadingService - hide() called but no loadings active');
      this.forceReset(); // Reset to ensure consistency
      return;
    }
    
    this.loadingCount--;
    
    if (this.loadingCount <= 0) {
      this.loadingCount = 0;
      this.loadingSignal.set(false);
      
      // Clear global timeout
      if (this.timeoutIds.has('global')) {
        clearTimeout(this.timeoutIds.get('global'));
        this.timeoutIds.delete('global');
      }
      
    }
    
    this.detectChanges();
  }

  /**
   * Hide loading for a specific ID
   */
  hideById(id: string) {
    if (!this.loadingIds.has(id)) {
      return;
    }
    
    this.loadingIds.delete(id);
    
    // Clear associated timeout
    if (this.timeoutIds.has(id)) {
      clearTimeout(this.timeoutIds.get(id));
      this.timeoutIds.delete(id);
    }
    
    this.loadingCount--;
    
    if (this.loadingCount <= 0) {
      this.loadingCount = 0;
      this.loadingSignal.set(false);
    }
    
    this.detectChanges();
  }

  /**
   * Force reset of loading state
   */
  forceReset() {
    // Clear all timeouts
    this.timeoutIds.forEach((timeoutId) => {
      clearTimeout(timeoutId);
    });
    
    this.timeoutIds.clear();
    this.loadingIds.clear();
    this.loadingCount = 0;
    this.loadingSignal.set(false);
    
    this.detectChanges();
  }

  /**
   * Force show loading regardless of current state
   */
  forceShowLoading() {
    this.forceReset();
    this.show();
  }

  /**
   * Force change detection in the application
   */
  private detectChanges() {
    try {
      this.appRef.tick();
    } catch (e) {
      console.error('Error forcing change detection:', e);
    }
  }

  /**
   * Check if any loading is active
   */
  get isLoading() {
    return this.loadingSignal();
  }

  /**
   * Get current count of active loadings
   */
  getLoadingCount() {
    return this.loadingCount;
  }

  /**
   * Get all active loading IDs
   */
  getActiveLoadingIds(): string[] {
    return [...this.loadingIds.keys()];
  }

  /**
   * Check if loading exists with specific ID
   */
  hasLoading(id: string): boolean {
    return this.loadingIds.has(id);
  }
  
  /**
   * Check for orphaned loadings that have been active for too long
   * and remove them to prevent them from getting "stuck"
   */
  private checkOrphanedLoadings() {
    // checkOrphanedLoadings
  }
}