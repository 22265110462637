import { inject, Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, EMPTY, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
// services
import { AuthService } from '../../services/auth.service';
import { IonicService } from 'src/app/services/ionic.service';
import { Router } from '@angular/router';
import { UserStore } from 'src/app/store/user.store';
// utils
import { isTokenExpired } from '../utils/is-token-expired';
import { environment } from 'src/environments/environment';
import { LoadingService } from 'src/app/services/loading.service';

@Injectable({
  providedIn: 'root',
})
export class RequestInterceptor implements HttpInterceptor {
  // Operaciones que no requieren autenticación
  readonly UNSECURED_OPERATIONS = [
    '/auth/login',
    '/auth/register',
    '/auth/forgot-password',
    '/auth/confirm-forgot-password',
    environment.urlImages,
    'maps.googleapis.com/',
  ];

  userStore = inject(UserStore);

  constructor(
    private authService: AuthService,
    private ionicService: IonicService,
    private router: Router,
    private loadingService: LoadingService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const token = this.userStore.user()?.tokenAuth
      ? // ? this.userStore.user()!.tokens
        // : JSON.parse(localStorage.getItem('user')!)?.tokens;
        this.userStore.user()!.tokenAuth
      : JSON.parse(localStorage.getItem('user')!)?.tokenAuth;

    if (this.isUnsecuredOperation(request)) {
      return next.handle(request);
    }

    if (token && !isTokenExpired(token) && !this.userStore.user()?.tokens) {
      this.userStore.updateUser(JSON.parse(localStorage.getItem('user')!));
    }

    if (token && isTokenExpired(token) && !this.isUnsecuredOperation(request)) {
      // Si el token ha expirado, manejar el cierre de sesión
      console.log('IF INTER')
      this.handleExpiredToken();
      this.authService.logout();
      this.loadingService.hide();
      this.router.navigate(['/home']);
      return EMPTY;
    }

    if (
      token &&
      !isTokenExpired(token) &&
      !this.isUnsecuredOperation(request)
    ) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
    }

    // return next.handle(request).pipe(
    //   catchError((error) => {
    //     console.log(error);
    //     return throwError(() => error);
    //   }),
    // );

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          console.error('Error 401: No autorizado', error);
          // Desloguear al usuario y redirigir
          this.authService.logout();
          this.router.navigate(['/']);
          this.loadingService.hide();
          this.ionicService.showAlert(
            'Acceso denegado',
            'Su sesión ha expirado. Inicie sesión nuevamente.',
            [{ text: 'Aceptar' }]
          );
          return EMPTY; // Detener flujo de ejecución
        }
        return throwError(() => error); // Otros errores
      }),
    );
  }

  private isUnsecuredOperation(request: HttpRequest<any>): boolean {
    return this.UNSECURED_OPERATIONS.some((operation) =>
      request.url.includes(operation),
    );
  }

  private handleExpiredToken() {
    this.ionicService.showAlert(
      'Sesión expirada',
      'Su sesión ha expirado. Inicie sesión nuevamente para continuar operando',
      [
        {
          text: 'Aceptar',
          handler: () => {
            this.ionicService.dismissAlert();
            this.authService.logout();
            this.router.navigate(['/home']);
          },
        },
      ],
    );
  }
}
