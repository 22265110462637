import { Component, OnInit, AfterViewInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingService } from '../../../services/loading.service';

@Component({
  selector: 'app-loading',
  standalone: true,
  imports: [CommonModule],
  template: `
    @if (isLoading) {
      <div class="fixed inset-0 bg-black/50 z-[99999] flex items-center justify-center loading-container">
        <div class="bg-white rounded-lg p-6 flex flex-col items-center shadow-xl">
          <div class="loading loading-spinner loading-lg text-primary"></div>
        </div>
      </div>
    }
  `,
  styles: [`
    .loading-container {
      animation: fadeIn 0.15s ease-in-out;
    }
    @keyframes fadeIn {
      from { opacity: 0; }
      to { opacity: 1; }
    }
  `]
})
export class LoadingComponent implements OnInit, AfterViewInit, OnDestroy {
  // Local variable for improved change detection
  isLoading = false;
  private checkInterval: any;

  constructor(
    public loadingService: LoadingService,
    private cdr: ChangeDetectorRef
  ) {}

  /**
   * Initialize component and set up loading state monitoring
   */
  ngOnInit() {
    this.updateLoadingState();
    
    // Set up interval to check loading state
    // This is a backup to ensure UI always reflects correct state
    this.checkInterval = setInterval(() => {
      const newState = this.loadingService.isLoading;
      if (this.isLoading !== newState) {
        this.isLoading = newState;
        this.cdr.detectChanges();
      }
    }, 100);
  }

  /**
   * Verify if there's a pending initial loading state
   */
  ngAfterViewInit() {
    this.updateLoadingState();
  }

  /**
   * Clean up interval when component is destroyed
   */
  ngOnDestroy() {
    if (this.checkInterval) {
      clearInterval(this.checkInterval);
    }
  }

  /**
   * Update local loading state and trigger change detection if needed
   */
  private updateLoadingState() {
    const newState = this.loadingService.isLoading;
    if (this.isLoading !== newState) {
      this.isLoading = newState;
      this.cdr.detectChanges();
    }
  }
}